<template>
  <div>
    <div class="wrapper" id="news">
      <!--------------------- loading --------------------->
      <Loading class="section-dark section-loading"></Loading>
      <!--------------------- section --------------------->
      <section v-if="!isLoading" key="content" class="section-duke section-dark jpadding">
        <div class="jtitle jtitle-80 jtitle-t40 title-w-filters duke-style">
          <h1>Latest News</h1>
          <!-- <div class="jbtns-container mt-4">
            <button
              @click="
                allNews();
                filters[active] = false;
              "
              class="jbtn-duke jbtn-outline-silver text-uppercase"
            >
              <i class="fal fa-file"></i> All
            </button>
            <button
              v-for="(category, index) in $C.NEWS_CATEGORIES"
              :key="index"
              @click="filterQuery(index)"
              v-bind:class="{ active: filters[index] }"
              class="jbtn-duke jbtn-outline-silver text-uppercase"
            >
              <i class="fal fa-file"></i> {{ category }}
            </button>
          </div> -->
        

        <!--------------------- horizontal --------------------->
        <vue-horizontal class="horizontal mt-4" :displacement="0.5" :button-between="false">
          <template v-slot:btn-prev>
            <svg class="btn-left" viewBox="0 0 24 24">
              <path
                d="m9.8 12 5 5a1 1 0 1 1-1.4 1.4l-5.7-5.7a1 1 0 0 1 0-1.4l5.7-5.7a1 1 0 0 1 1.4 1.4l-5 5z"
              />
            </svg>
          </template>

          <template v-slot:btn-next>
            <svg class="btn-right" viewBox="0 0 24 24">
              <path
                d="m14.3 12.1-5-5a1 1 0 0 1 1.4-1.4l5.7 5.7a1 1 0 0 1 0 1.4l-5.7 5.7a1 1 0 0 1-1.4-1.4l5-5z"
              />
            </svg>
          </template>
          <!-- content -->
          <div class="horizontal-item">
            <button
              @click="allNews(); filters[active] = false;"
              class="jbtn-duke jbtn-outline-silver text-uppercase"
            >
              <i class="fal fa-file"></i> All
            </button>
            <button
              v-for="(category, index) in $C.NEWS_CATEGORIES"
              :key="index"
              @click="filterQuery(index)"
              v-bind:class="{ active: filters[index] }"
              class="jbtn-duke jbtn-outline-silver text-uppercase"
            >
              <i class="fal fa-file"></i> {{ category }}
            </button>
          </div>
        </vue-horizontal>
      </div>

        <!--------------------- grid --------------------->
        <div v-if="newsList.length" key="list" class="grid-2 align-content-start">
          <div v-for="(news, index) in newsList" :key="news.id" class="jcard-duke jcard-news">
            <div
              v-if="news.customCover && news.hasCoverImage"
              class="news-cover-image"
              :style="'background-image: url(\'' + news.coverUrl + '\')'"
            ></div>
            <div v-else class="news-cover-image"></div>
            <div class="news-content">
              <h5>
                {{ news.title }}
                <button class="jbtn-text text-silver ml-2" v-if="news.hasPdf" @click="openPdf(news.pdfUrl)">
                  <i class="fal fa-file-pdf"></i>
                </button>
              </h5>
              <p class="news-subtitle m-0">
                {{ $C.NEWS_CATEGORIES[news.category] }} |
                {{ $moment($C.getDateFromTimestamp(news.publishedOn)).format("DD-MM-YYYY") }}
              </p>

              <p class="p-text m-0 mt-3">{{ news.text }}</p>
            </div>
          </div>
        </div>

        <!--------------------- empty --------------------->
        <div v-else key="empty" class="section-empty">
          <h5 class="mb-3"><i class="fad fa-empty-set fa-2x"></i><br /></h5>
          <p class="p-text-s">
            This list is empty at the moment.
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import VueHorizontal from "vue-horizontal";
import Loading from "@/components/general/Loading";
import Authstore from "@/store/store.js";
import C from "@/const";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;

export default {
  name: "News",
  components: {
    Loading: Loading,
    VueHorizontal
  },
  data() {
    return {
      isLoading: true,
      isEmpty: false,

      newsList: [],

      filters: [false, false, false],

      listenerRegistrations: [],
    };
  },
  methods: {
    allNews: function() {
      this.isLoading = true;

      if (this.waitingForClaims()) return;

      const permission = this.userType.toLowerCase();
      const registration = db.collection(C.COLLECTION.NEWS)
        .where("permissions", "array-contains", permission)
        .where("published", "==", true)
        .onSnapshot(querySnapshot => {
          this.newsList.splice(0);
          querySnapshot.forEach(doc => {
            const docdata = doc.data();
            docdata.id = doc.id;
            this.newsList.push(docdata);
          });
          this.loadingDone();
        });
      // .catch((error) => {
      //   this.loadingDone(error);
      // });

      this.listenerRegistrations.push(registration);
    },
    loadingDone(error) {
      this.$root.$emit("loadingDone", error);
      if (!error) this.isLoading = false;
    },

    filterQuery: function(filter) {
      if (this.filters[filter]) return;

      if (this.waitingForClaims()) return;

      const permission = this.userType.toLowerCase();
      this.newsList.splice(0);
      var ref = db.collection(C.COLLECTION.NEWS).where("permissions", "array-contains", permission);
      if (filter >= 0) {
        ref = ref.where("category", "==", filter);
        this.changeActiveFilter(filter);
      }

      ref
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const docdata = doc.data();
            docdata.id = doc.id;
            this.newsList.push(docdata);
          });
          this.loadingDone();
        })
        .catch(error => this.loadingDone(error));
    },
    changeActiveFilter(clicked) {
      this.$set(this.filters, this.active, false);
      this.$set(this.filters, clicked, true);
      this.active = clicked;
    },
    //open pdf file in new tab
    openPdf(url) {
      console.log("Opening PDF...");
      window.open(url, "_blank"); //to open in new tab
    },
    waitingForClaims() {
      if (!this.userType) {
        Authstore.dispatch("getClaims").then(
          error => this.handleClaimsError(error),
          result => {
            if (!result.member && !result.owner && !result.press && !result.admin) {
              this.handleClaimsError();
            } else {
              this.allNews();
            }
            return;
          }
        );
        return true;
      }
      return false;
    },
    handleClaimsError(error) {
      console.log("handleClaimsError");
      this.loadingDone("Authentication error, please reauthenticate.");

      if (error) console.log("In router: could not get claims" + error);

      // TODO: make user sign in again?
    }
  },
  computed: {
    userType: {
      get() {
        return this.$store.getters.getUserTypeString;
      }
    }
  },
  mounted() {
    this.allNews();
  },
  beforeDestroy() {    
    if (this.listenerRegistrations.length > 0) {
      this.listenerRegistrations.forEach(registration => {
        registration();
      });
    }
  }
};
</script>

<style scoped lang="scss">
.btn-left,
.btn-right {
  padding: 6px;
  height: 100%;
}

.btn-left {
  background: linear-gradient(to left, #23232300 0, #232323 50%, #232323);
}

.btn-right {
  background: linear-gradient(to right, #23232300 0, #232323 50%, #232323);
}
</style>
